import { Session } from "../../hooks/session/session-hook.types";
import { AssetSpot } from "../../pages/assets-menu/asset-movement/context";
import {
  AssetData,
  ImportAssetData,
} from "../../pages/assets-menu/assets-form/context";
import {
  AssetOcurrence,
  OcurrenceInput,
} from "../../pages/assets-menu/assets-ocurrency/context";
import { Asset } from "../../pages/assets-menu/assets/context";
import { Genre } from "../../pages/settings/genres/context";
import { useFetch } from "../fetch/fetch-hook";
import { Pagination } from "../user/user-hook.types";

const useAsset = () => {
  const { doFetch } = useFetch();

  const fetchAssets = async (
    session?: Session,
    pagination?: Pagination,
    searchQuery: Partial<Asset> = {}
  ) => {
    const {
      description,
      number,
      rfid,
      invoiceNumber,
      location,
      locationId,
      genre,
      acquireAmount,
      invoiceDate,
    } = searchQuery;

    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/asset`,
        query: `description=${description || ""}&number=${number || ""}&rfid=${
          rfid || ""
        }&invoiceNumber=${invoiceNumber || ""}&location=${location || ""}${
          locationId ? "&locationId=" + locationId : ""
        }&genre=${genre || ""}&acquireAmountMin=${
          acquireAmount?.min || ""
        }&acquireAmountMax=${acquireAmount?.max || ""}&invoiceDateMin=${
          invoiceDate?.min || ""
        }&invoiceDateMax=${invoiceDate?.max || ""}&pendingRegistration=${
          acquireAmount?.hasAcquireAmount
        }`,
      },
    });
    return data;
  };

  const fetchAssetsExport = async (
    session: Session,
    searchQuery: Partial<Asset> = {}
  ) => {
    const {
      description,
      number,
      invoiceNumber,
      location,
      genre,
      acquireAmount,
      invoiceDate,
    } = searchQuery;

    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/asset-export`,
        query: `description=${description || ""}&number=${
          number || ""
        }&invoiceNumber=${invoiceNumber || ""}&location=${
          location || ""
        }&genre=${genre || ""}&acquireAmountMin=${
          acquireAmount?.min || ""
        }&acquireAmountMax=${acquireAmount?.max || ""}&invoiceDateMin=${
          invoiceDate?.min || ""
        }&invoiceDateMax=${invoiceDate?.max || ""}`,
      },
    });
    return data;
  };

  const fetchSingleAsset = async (session?: Session, searchQuery?: Asset) => {
    const { id } = searchQuery!;
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/asset/${id}`,
      },
    });

    return data;
  };

  const fetchPicturesUrls = async (session: Session, assetId: string) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/pictures-assets-urls/${assetId}`,
      },
    });

    return data;
  };

  const fetchDescriptionAssets = async (
    description: string,
    session?: Session
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/assetdescription`,
        query: `description=${description || ""}`,
      },
    });
    return data;
  };

  const fetchReminder = async (session: Session, assetId: string) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/asset-reminder/${assetId}`,
      },
    });
    return data;
  };

  const createAsset = async (session?: Session, asset?: Asset) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(asset),
        method: "POST",
        path: `v2/asset`,
      },
    });
    return data;
  };

  const createManyAssets = async (
    session?: Session,
    assets?: ImportAssetData[]
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(assets),
        method: "POST",
        path: `v2/import-assets`,
      },
    });
    return data;
  };

  const updateAsset = async (session?: Session, asset?: AssetData) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(asset),
        method: "PUT",
        path: `v2/asset/${asset?.id}`,
      },
    });
    return data;
  };

  const fetchMovements = async (
    session?: Session,
    pagination?: Pagination,
    searchQuery?: AssetSpot
  ) => {
    const {
      description: assetName,
      dateInMin,
      dateInMax,
      dateOutMin,
      dateOutMax,
      assetId,
      movementOnly,
    } = searchQuery!;

    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/assetspot`,
        query: `description=${assetName || ""}&dateInMin=${
          dateInMin || ""
        }&dateInMax=${dateInMax || ""}&dateOutMin=${
          dateOutMin || ""
        }&dateOutMax=${dateOutMax || ""}&movementOnly=${movementOnly}&assetId=${
          assetId || ""
        }`,
      },
    });
    return data;
  };

  const moveAsset = async (
    session?: Session,
    assetId?: string,
    newLocalId?: string
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify({
          locationId: newLocalId,
        }),
        method: "PUT",
        path: `v2/assetspot/${assetId}`,
      },
    });
    return data;
  };

  const fetchOcurrence = async (
    session?: Session,
    pagination?: Pagination,
    searchQuery?: AssetOcurrence
  ) => {
    const {
      assetId,
      acquireAmountMin,
      acquireAmountMax,
      createdAt,
      updatedAt,
      futureSellAmountMin,
      futureSellAmountMax,
      invoiceDate,
      remainingAmountMin,
      remainingAmountMax,
      yearsToDepreciate,
      endDate,
      startDate,
      assetName,
    } = searchQuery!;

    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/assetoccurrence`,
        query: `acquireAmountMin=${acquireAmountMin || ""}&acquireAmountMax=${
          acquireAmountMax || ""
        }&createdAt=${createdAt || ""}&updatedAt=${
          updatedAt || ""
        }&futureSellAmountMin=${
          futureSellAmountMin || ""
        }&futureSellAmountMax=${futureSellAmountMax || ""}&invoiceDate=${
          invoiceDate || ""
        }&remainingAmountMin=${remainingAmountMin || ""}&remainingAmountMax=${
          remainingAmountMax || ""
        }&yearsToDepreciate=${yearsToDepreciate || ""}&endDate=${
          endDate || ""
        }&startDate=${startDate || ""}&assetId=${assetId || ""}&assetName=${
          assetName || ""
        }`,
      },
    });
    return data;
  };

  const newOcurrence = async (
    ocurrenceInput: OcurrenceInput,
    assetId: string,
    session?: Session
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(ocurrenceInput),
        method: "PUT",
        path: `v2/assetoccurrence/${assetId}`,
      },
    });
    return data;
  };

  const fetchGenresList = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/genre`,
      },
    });
    return data;
  };

  const fetchGenresAccount = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/companiesgenre`,
      },
    });
    return data;
  };

  const updateGenreAccount = async (session?: Session, genre?: Genre) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(genre),
        method: "PUT",
        path: `v2/companiesgenre/${genre?.id}`,
      },
    });
    return data;
  };

  const fetchCalculatedDepreciation = async (
    session?: Session,
    pagination?: Pagination,
    searchQuery?: Asset
  ) => {
    const {
      description,
      number,
      invoiceDate,
      acquireAmount,
      genre,
      dateEndCalculation,
    } = searchQuery || {};

    return await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/asset-calculation`,
        query: `description=${description || ""}&number=${number || ""}&genre=${
          genre || ""
        }&acquireAmountMin=${acquireAmount?.min || ""}&acquireAmountMax=${
          acquireAmount?.max || ""
        }&invoiceDateMin=${invoiceDate?.min || ""}&invoiceDateMax=${
          invoiceDate?.max || ""
        }&pendingRegistration=${
          acquireAmount?.hasAcquireAmount
        }&dateEndCalculation=${dateEndCalculation || ""}`,
      },
    });
  };

  const fetchCalculatedDepreciationExport = async (
    session?: Session,
    searchQuery?: Asset
  ) => {
    const {
      description,
      number,
      invoiceDate,
      acquireAmount,
      genre,
      dateEndCalculation,
      calculation,
    } = searchQuery || {};

    return await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/asset-calculation-export`,
        query: `description=${description || ""}&number=${number || ""}&genre=${
          genre || ""
        }&acquireAmountMin=${acquireAmount?.min || ""}&acquireAmountMax=${
          acquireAmount?.max || ""
        }&invoiceDateMin=${invoiceDate?.min || ""}&invoiceDateMax=${
          invoiceDate?.max || ""
        }&pendingRegistration=${
          acquireAmount?.hasAcquireAmount
        }&dateEndCalculation=${
          dateEndCalculation || ""
        }&calculation=${calculation}`,
      },
    });
  };

  return {
    fetchAssets,
    fetchAssetsExport,
    fetchSingleAsset,
    fetchPicturesUrls,
    fetchDescriptionAssets,
    fetchReminder,
    createAsset,
    createManyAssets,
    updateAsset,
    fetchMovements,
    moveAsset,
    fetchOcurrence,
    newOcurrence,
    fetchGenresList,
    fetchGenresAccount,
    updateGenreAccount,
    fetchCalculatedDepreciation,
    fetchCalculatedDepreciationExport,
  };
};

export { useAsset };
